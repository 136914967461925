import React from "react"
import "./highlight.css"

export default class Highlight extends React.Component {
  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    return <span className="highlight" ref={this.myRef}>{this.props.value}</span>
  }

  componentDidMount(){
    let node = this.myRef.current;
    this.highlight(node);
    document.addEventListener("scroll", () => {
      this.highlight(node);
    });
  }

  highlight(node){
    if(node.scrollTop = 300){
      setTimeout(() => {
        node.style = "background-size: 100% 20px;"
      }, 500);
    }
  }
}
