import React from "react"
import "./header.css"
import LineAnimation from "../line-animation/line-animation"

export default class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <div className="header__back-ground">
          <div className="header__content">
            <h1 className="header__title">Vastgoed Aankoopbegeleiding</h1>
            <LineAnimation color="rgb(30, 30, 30)" margin="0 auto" position="center"></LineAnimation>
            <h2 className="header__subtitle">
              By Immophone-partners
              <br />
              0477 624 790
            </h2>
          </div>
        </div>
      </header>
    )
  }
}
