import React from "react"
import Layout from "../layouts/layout/layout"
import HeaderSmall from "../components/header-small/header-small"
import HeaderImage from "../../static/media/card-one.jpg"
import FeatureList from "../components/feature-list/feature-list"
import DefaultLayout from "../layouts/default-layout"
import DefaultTextBlock from "../components/default-text-block/default-text-block"

const items = [
  { text: "Vanaf €350 + BTW", key: 1, icon: "fas fa-money-bill-wave-alt" },
  { text: "Eigendommen tot 250m² (€1 + BTW per extra m²)", key: 2, icon: "fas fa-ruler-combined" },
  {
    text: "Vervoer 50km inbegrepen (€0,14 + BTW per extra km)",
    key: 3,
    icon: "fas fa-car",
  },
]

export default () => (
  <Layout>
    <HeaderSmall title="Tech-check" imageUrl={HeaderImage}></HeaderSmall>
    <DefaultLayout>
      <div className="details">
        <DefaultTextBlock
          title="Tech-check"
          text="Complete technische doorlichting voor aankoop. Op basis van 400 punten doorgronden we de hele eigendom zodat ze voor jullie geen verassingen meer heeft."
        ></DefaultTextBlock>
        <FeatureList items={items} title="Concreet"></FeatureList>
      </div>
    </DefaultLayout>
  </Layout>
)
