import React from "react"

import DefaultLayout from "../layouts/default-layout"
import DefaultContentBlock from "../components/default-content-block/default-content-block"
import CenteredContentBlock from "../components/centered-content-block/centered-content-block"
import CardLayout from "../layouts/card-layout/card-layout"
import Layout from '../layouts/layout/layout'
import Header from '../components/header/header'

export default () => (
  <Layout>
    <Header></Header>
    <DefaultLayout>
      <DefaultContentBlock
        title="Een eigendom aankopen zonder zorgen."
        subtitle="Een objectieve en deskundige beoordeling van uw toekomstige eigendom door experten kan u veel geld en zorgen besparen."
        content="Eigendommen worden vaak &ndash; bewust of onbewust &ndash; te mooi voorgesteld. Zaken worden verdoezeld of je ziet het gewoon niet. Wat uw droomhuis moest worden, werd een nachtmerrie! Een objectieve en deskundige beoordeling door experten kan u veel besparen, zowel financieel als emotioneel. Soms gebeurt een aankoop onder bepaalde tijdsdruk en neem je daarom foute beslissingen. Die gevolgen kunnen nog lange tijd nazinderen."
      ></DefaultContentBlock>
      <CenteredContentBlock
        title="Wat doen we voor u?"
        content="We onderzoeken het functioneren van alle technische componenten o.a. afvoeren, verwarming, asbest, verwarmingsinstallaties, vochtproblemen (binnen en buiten)...
            We controleren een eigendom op 400 punten zodat u met een gerust hart uw eigendom kan kopen! Dit vanaf 350€ + btw."
      ></CenteredContentBlock>
      <CardLayout></CardLayout>
    </DefaultLayout>
  </Layout>
)
