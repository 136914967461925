import React from "react"
import Helmet from "react-helmet"
import Footer from "../../components/footer/footer"
import Notification from "../../components/notification/notification"
import Navigation from "../../components/navigation/navigation"
import Favicon from "../../../static/media/logo-black.png"

export default ({ children }) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Vastgoed aankoop-begeleiding</title>
      <link rel="icon" href={Favicon}></link>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#2e8b57" />
      <link
        href="https://fonts.googleapis.com/css?family=Libre+Baskerville:700|Roboto:300,400,700&display=swap"
        rel="stylesheet"
      ></link>
      <script
        src="https://kit.fontawesome.com/96a75ec425.js"
        crossorigin="anonymous"
      ></script>
    </Helmet>
    <div style={{ minHeight: "100vh" }}>
      <Notification
        title="Under construction"
        description="Deze site is in opbouw."
      ></Notification>
      <Navigation></Navigation>
      {children}
    </div>
    <Footer></Footer>
  </div>
)
