import React from 'react'
import './default-text-block.css'
import LineAnimation from '../line-animation/line-animation'

export default class DefaultTextBlock extends React.Component{
   render(){
      return(
         <div className="default-text-block">
            <h2 className="subtitle">{ this.props.title }</h2>
            <LineAnimation color="#0c973a"></LineAnimation>
            <div className="content">
               { this.props.text }
            </div>
         </div>
      )
   }
}