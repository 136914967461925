import React from "react"
import "./footer.css"

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        Vastgoed aankoopbegeleiding by Immophone-partners | Under construction | 0477 624 790
      </div>
    )
  }
}
