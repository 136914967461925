import React from "react"
import "./desktop-navigation.css"
import FontAwesome from "react-fontawesome"
import { Link } from "gatsby"

export default class DesktopNavigation extends React.Component {
  render() {
    return (
      <div className="desktop-navigation">
        <ul className="desktop-navigation__items">
          <li>
            <Link to="/tech-check"><FontAwesome className="fas fa-check" name="" style={{marginRight: '10px', opacity: '.7'}}/> Techcheck</Link>
          </li>
          <li>
            <Link to="/full-check"><FontAwesome className="fas fa-check-double" name="" style={{marginRight: '10px', opacity: '.7'}}/> Fullcheck</Link>
          </li>
          <li>
            <Link to="/contact"><FontAwesome className="fas fa-paper-plane" name="" style={{marginRight: '10px', opacity: '.7'}}/> Contact</Link>
          </li>
        </ul>
      </div>
    )
  }
}
