import React from "react"
import "./card-layout.css"

import Card from "../../components/card/card"
import CardOne from "../../../static/media/card-one.jpg";
import CardTwo from "../../../static/media/card-two.jpg";

export default class CardLayout extends React.Component {
  render() {
    return (
      <div className="card-layout">
        <Card
          title="Tech-check"
          price="Vanaf €350 excl. btw"
          imagePath={CardOne}
          actionText="meer info"
          actionUrl="/tech-check"
          description="Complete technische doorlichting voor aankoop. Op basis van 400 punten doorgronden we de hele eigendom zodat ze voor jullie geen verassingen meer heeft."
        ></Card>
        <Card
          title="Full-check"
          price="Vanaf €550 excl. btw"
          imagePath={CardTwo}
          actionUrl="/full-check"
          actionText="meer info"
          description="Juridische doorlichting op 500 punten, objectieve waarde-bepaling, argumenten bij uw prijs-onderhandeling, hulp zoals we het voor ons zelf zouden doen."
        ></Card>
        {/* <Card
          title="Investment-check"
          price="vanaf 750 € excl. btw"
          imagePath={CardOne}
          actionText="Vraag offerte aan"
          actionUrl="/go"
          description="Investeringsanalyse voor aankoop van uw gehele patrimonium. Verkopen of houden? Renovatie en kostprijsanalyse. Advies voor het juiste rendement."
        ></Card> */}
      </div>
    )
  }
}
