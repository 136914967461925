import React from "react"
import Layout from "../layouts/layout/layout"
import HeaderSmall from "../components/header-small/header-small"
import HeaderImage from "../../static/media/card-two.jpg"
import FeatureList from "../components/feature-list/feature-list"
import DefaultLayout from "../layouts/default-layout"
import DefaultTextBlock from "../components/default-text-block/default-text-block"
import Highlight from "../components/highlight/highlight"

const items = [
  { text: "Vanaf €550 + BTW", key: 1, icon: "fas fa-money-bill-wave-alt" },
  // { text: "Eigendommen tot 250m² (€1 + BTW per extra m²)", key: 2, icon: "" },
  {
    text: "Vervoer 50km inbegrepen (€0,14 + BTW per extra km)",
    key: 3,
    icon: "fas fa-car",
  },
]

export default () => (
  <Layout>
    <HeaderSmall title="Full-check" imageUrl={HeaderImage}></HeaderSmall>
    <DefaultLayout>
      <div className="details">
        <DefaultTextBlock
          title="Full-check"
          text={<div>Juridische doorlichting op, objectieve waarde-bepaling, argumenten bij uw prijs-onderhandeling, hulp zoals we het voor ons zelf zouden doen.</div>}
        ></DefaultTextBlock>
        <FeatureList items={items} title="Concreet"></FeatureList>
      </div>
    </DefaultLayout>
  </Layout>
)
