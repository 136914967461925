import React from "react"
import Layout from "../layouts/layout/layout"
import HeaderSmall from "../components/header-small/header-small"
import FeatureList from "../components/feature-list/feature-list"
import DefaultLayout from "../layouts/default-layout"
import DefaultTextBlock from "../components/default-text-block/default-text-block"
import ContactForm from "../components/contact-form/contact-form"
import CenteredContentBlock from "../components/centered-content-block/centered-content-block"
import DefaultContentBlock from "../components/default-content-block/default-content-block"

const items = [
  { text: "0477 624 790", key: 1, icon: "fas fa-phone-square-alt" },
  { text: "info@vastgoed-aankoopbegeleiding.be", key: 2, icon: "far fa-envelope" },
]

export default () => (
  <Layout>
    <HeaderSmall title="Get in touch!" imageUrl="https://images.unsplash.com/photo-1560867486-c5c696b93505?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=775&q=80"></HeaderSmall>
    <DefaultLayout>
      <CenteredContentBlock content={<span>Hi! &#9995; <br></br> Meer info of een offerte aanvragen? Het kan via onderstaand formulier, telefonisch of via mail.</span>}></CenteredContentBlock>
      <div className="contact details">
        <DefaultTextBlock
          title="Contact formulier"
          text={<ContactForm></ContactForm>}
        ></DefaultTextBlock>
        <FeatureList items={items} title="Gegevens"></FeatureList>
      </div>
    </DefaultLayout>
  </Layout>
)
